import React, { useEffect } from 'react'

import Brain39 from '../../../assets/images/Resultados/Brain39.png'
import Brain59 from '../../../assets/images/Resultados/Brain59.png'
import Brain69 from '../../../assets/images/Resultados/Brain69.png'
import Brain89 from '../../../assets/images/Resultados/Brain89.png'
import Brain99 from '../../../assets/images/Resultados/Brain99.png'
import Brain100 from '../../../assets/images/Resultados/Brain100.png'

import { urlResultados } from '../../../helpers/endpoints'
import api from '../../../helpers/Api'
import { useNavigate } from 'react-router-dom'
import { ParticlesF } from '../../ParticlesF'


//import { BsFillStopwatchFill } from 'react-icons/bs'

export const Results = ({
    preguntas,
    score,
    onReset,
    Inicio,
    Final,

    Id_Estudiante,
    Id_Grado,
    Id_Tema,
    Id_Leccion,
    Q_Tiempo,
    Q_Puntaje,
    Q_Total,
    Q_TiempoUso
}) => {

    const r_porcentaje = c_porcentaje(score, Q_Total);
    let imageSrc;
    let message;


    if (r_porcentaje <= 39) {
        //0-39
        imageSrc = Brain39;
        message = "¡Sigue Intentándolo!";
    } else if (r_porcentaje <= 59) {
        // 40-59
        imageSrc = Brain59;
        message = "¡Continúa Practicando!";
    } else if (r_porcentaje <= 69) {
        // 60-69
        imageSrc = Brain69;
        message = "¡Trabajo Satisfactorio!";
    } else if (r_porcentaje <= 89) {
        // 70-89
        imageSrc = Brain89;
        message = "¡Bien Hecho!";
    } else if (r_porcentaje <= 99) {
        // 90-99
        imageSrc = Brain99;
        message = "¡Impresionante!";
    } else {
        // 100
        imageSrc = Brain100;
        message = "¡Excelente Trabajo!";
    }

    useEffect(() => {
        const putResultados = async () => {
            try {
                const response = await api.post(`${urlResultados}`, { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema, IdLeccion: Id_Leccion, PunjateEstudiante: score, FechaInicio: Inicio, FechaFinal: Final, FechaInicioUso: Q_TiempoUso, FechaFinalUso: Final });
            } catch (error) {
                console.log(error);
            }
        }
        putResultados();
    }, [Id_Estudiante, Id_Grado, Id_Tema, Id_Leccion, Q_TiempoUso, score, Inicio, Final]);


    const tiempoObtenido = () => {
        if (Inicio && Final) {
            const TInicio = new Date(Inicio);
            const TFinal = new Date(Final);

            const diferencia = TFinal - TInicio;
            const seconds = Math.floor((diferencia % (1000 * 60)) / 1000);
            const minutes = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
            const hours = Math.floor((diferencia / (1000 * 60 * 60)));

            const formato = (num) => (num < 10 ? `0${num}` : num);

            return `${formato(hours)}:${formato(minutes)}:${formato(seconds)}`;
        }
        return null;
    };

    const navigate = useNavigate();
    return (

        <div className="h-full mx-auto max-w-2xl px-2 py-5 sm:p-6 lg:flex lg:items-center lg:justify-center lg:max-w-7xl lg:px-8 bg-blue-300">
            <ParticlesF />
            <div className='h-full w-full p-2 bg-pink-400 lg:mx-20 rounded-2xl'>
                <div className="w-full h-full bg-sky-200 p-2 flex flex-col justify-center items-center rounded-2xl">
                    <h1 className='font-comforter text-2xl sm:text-3xl md:text-4xl text-blue-900 md:mb-4'>Li Xnumsi</h1>
                    <h3>{message}</h3>
                    <div className="bg-green-300 p-2 my-4 rounded-2xl">
                        <img className='h-64' src={imageSrc} alt="Resultado" />
                    </div>
                    <div className='w-full flex flex-col sm:flex-row items-center justify-around'>



                        <div className='w-full sm:w-2/5 lg:w-3/12 pb-2 sm:py-2'>
                            <div className='rounded-t-lg bg-indigo-500'>
                                <h1 className='md:text-xl font-medium text-white text-center'>Jarub´risi ut hoonal roksi</h1>
                            </div>
                            <div className='flex items-center justify-center border-x-4 border-b-4 border-indigo-500 rounded-b-lg h-10 bg-gray-200'>
                                <h1 className='text-xl md:text-2xl font-medium text-center'>{tiempoObtenido()}</h1>
                            </div>
                        </div>




                        <div className='w-full sm:w-2/5 lg:w-3/12 pt-2 sm:py-2'>
                            <div className='rounded-t-lg bg-indigo-500'>
                                <h1 className='md:text-xl font-medium text-white text-center'>Jarub´risi hoonal</h1>
                            </div>
                            <div className='flex items-center justify-center border-x-4 border-b-4 border-indigo-500 rounded-b-lg h-10 bg-gray-200'>
                                <h1 className='text-xl md:text-2xl font-medium text-center'>{score}/{preguntas.length}</h1>
                            </div>
                        </div>

                    </div>
                    <button className="text-lg font-semibold bg-orange-300 rounded-3xl py-2 px-5 mt-8 md:text-2xl hover:bg-orange-500 hover:text-white" onClick={() => navigate("/Estudiantes/Lecciones", { state: { LDatos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema } } })}>Regresar a las Lecciones</button>
                </div>
            </div>

        </div>

    )
}

const c_porcentaje = (puntuacion, cantidadPreguntas) => {
    return (puntuacion / cantidadPreguntas) * 100;
};

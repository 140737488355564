import React from "react";
import HomeTeacher from "../components/Teacher/HomeTeacher";


const TeacherHomePage = () => {
  
  return (
        <HomeTeacher/>
  )
}

export default TeacherHomePage
import React from 'react'
import InformationAdditional from '../../../components/Student/Additional/InformationAdditional'

const InformationPageAdditional = () => {
    return (
        <div className="bg-gray-200 h-full">
            <InformationAdditional />
        </div>
    )
}

export default InformationPageAdditional
import React from 'react';
// import {Login} from '../components/Login';
import {Login} from '../../components/Others/Login'
// import { ParticlesT } from '../../components/ParticlesT';


const LoginPage = () => {
  return (
    <div>
      <Login/>
    </div>
  )
}
export default LoginPage;
import { useEffect, useMemo, useState } from "react";
import api from "../../../helpers/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { urlLeccionesAdicionales } from "../../../helpers/endpoints";
import { motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosLock } from "react-icons/io";


const NumeroLeccion = require.context("../../../assets/images/Resultados", true);

function generateUniqueRandomNumbers(count, max) {
    const numbers = [];
    while (numbers.length < count) {
        const randomNumber = Math.floor(Math.random() * max);
        if (!numbers.includes(randomNumber)) {
            numbers.push(randomNumber);
        }
    }
    return numbers;
}



const LectionHomeAdditional = () => {
    const location = useLocation();

    const Id_Estudiante = location?.state?.datos?.IdEstudiante || null;
    const Id_Grado = location?.state?.datos?.IdGrado || null;
    const Id_Tema = location?.state?.datos?.IdTema || null;

    const [hoverOut, setHoverOut] = useState(false);
    const [lecciones, setLecciones] = useState([]); // Usamos el hook useState para manejar el estado de la lista

    const randomNumbers = useMemo(() => generateUniqueRandomNumbers(lecciones.length, 7), [lecciones.length]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // Usamos el hook useEffect para ejecutar el request cuando el componente se monta
        // Declaramos una función asíncrona que hace el request
        const getLecciones = async () => {
            try {
                // Usamos await para esperar la respuesta del request

                const response = await api.get(`${urlLeccionesAdicionales}?IdTema=${Id_Tema}&IdGrado=${Id_Grado}&IdEstudiante=${Id_Estudiante}`);

                // Actualizamos el estado con la lista que recibimos
                setLecciones(response.data);

            } catch (error) {
                // Manejamos el error
                console.log(error);
            }
        };
        // Invocamos la función asíncrona
        getLecciones();
    }, [Id_Estudiante, Id_Grado, Id_Tema]); // El array vacío indica que solo se ejecuta una vez

    const navigate = useNavigate();

    return (
        <div className="bg-gray-200 h-full">
            <div className="mx-auto max-w-2xl h-full px-4 py-5 sm:px-6 sm:py-7 lg:max-w-7xl lg:px-8 bg-blue-300">
                <div className="w-10" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => { setHoverOut(false) }}>
                    <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={() => navigate("/Estudiantes/TemasAdicionales", { state: { datos: { codigoUsuario: Id_Estudiante } } })}>
                        <IoIosArrowBack className='font-bold text-lg' />
                        <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Atrás</h3>
                    </button>
                </div>
                <div className="flex justify-center py-2 border-b-4 border-b-orange-500">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="py-1 text-5xl font-extrabold mx-auto">
                        <h2 className="bg-clip-text text-blue-800 text-center">
                            Lecciones
                        </h2>
                    </motion.div>
                </div>


                <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-10 lg:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
                    {lecciones.map((leccion, index) => {
                        const randomIndex = randomNumbers[index];
                        return (
                            leccion.id_tipo_leccion === 1 ? (
                                <div key={leccion.id_leccion} className="relative w-full h-[450px] sm:h-[460px] flex flex-col justify-between cursor-pointer transition-transform duration-150 transform hover:scale-105 hover:border-2 hover:border-orange-500 rounded-2xl" onClick={() => navigate("/Estudiantes/InformacionAdicional", { state: { LDatos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: leccion.id_tema, IdLeccion: leccion.id_leccion, Tiempo: leccion.asignaciones[0].resultados[0].tiempo_transcurrido, PuntajeL: leccion.asignaciones[0].resultados[0].puntaje, Total: leccion.cantidad_preguntas, RandomNumber: `Brain${randomIndex}.png` } } })}>
                                    <div className="relative flex justify-center items-center bg-indigo-200 w-full h-[250px] rounded-2xl before:absolute before:bottom-0 before:left-1/2 before:w-6 before:h-6 before:bg-transparent before:rounded-full before:shadow-before after:absolute after:bottom-[70px] after:left-0 after:w-6 after:h-6 after:bg-transparent after:rounded-full after:shadow-after bg-cover">
                                        <img
                                            src={NumeroLeccion(`./Brain${randomIndex}.png`)}
                                            alt={leccion.nombre_leccion}
                                            className="h-full w-auto pb-20 rounded-2xl"
                                        />
                                    </div>

                                    <div className="relative w-full h-[190px] sm:h-[200px] bg-indigo-500 rounded-2xl rounded-tl-none">
                                        <div className="absolute left-0 w-1/2 h-[80px] -top-[80px] bg-indigo-500 border-t-[10px] border-r-[10px] border-blue-300 rounded-tr-3xl before:absolute before:w-6 before:h-6 before:bg-transparent before:rounded-full before:shadow-before2 after:absolute after:bottom-0 after:-right-6 after:w-6 after:h-6 after:bg-transparent after:rounded-full after:shadow-after4">
                                            <div className="w-full h-full flex justify-center items-center mt-2 pb-1 px-1">
                                                <h2 className="text-white capitalize font-semibold text-base text-center whitespace-pre-line sm:text-xl">{leccion.nombre_leccion}</h2>
                                            </div>
                                        </div>

                                        <div className="p-4 text-white flex flex-col justify-center">
                                            <div className="flex justify-center mb-2">
                                                <h3 className="font-semibold">Jarub´risi ut hoonal roksi:</h3>
                                            </div>

                                            <div className="flex flex-row justify-between mb-2">
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(0, 2)}</h4>
                                                    <h4>Hoonal</h4>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    <h4>:</h4>
                                                </div>
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(3, 5)}</h4>
                                                    <h4>Miin</h4>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    <h4>:</h4>
                                                </div>
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(-2)}</h4>
                                                    <h4>K´asal</h4>
                                                </div>
                                            </div>

                                            <div className="flex justify-center my-2">
                                                <h3 className="font-semibold">Jarub´risi hoonal:</h3>
                                            </div>

                                            <div className="container mx-auto">
                                                <div className="flex items-center justify-center">
                                                    <div className="relative w-full h-full bg-gray-200 rounded-lg">

                                                        <div className="bg-yellow-300 rounded-lg h-8 sm:h-10" style={{ width: `${(leccion.asignaciones[0].resultados[0].puntaje / leccion.cantidad_preguntas) * 100}%` }}>
                                                        </div>
                                                        <div className="absolute inset-y-0 inset-x-0">
                                                            <div className="flex items-center justify-center h-full w-full">
                                                                <h3 className="text-orange-500 font-semibold text-xl sm:text-2xl">{leccion.asignaciones[0].resultados[0].puntaje}/{leccion.cantidad_preguntas}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            ) : (
                                <div key={leccion.id_leccion} className="relative w-full h-[450px] sm:h-[460px] flex flex-col justify-between cursor-pointer transition-transform duration-150 transform hover:scale-105 hover:border-4 hover:border-dotted hover:border-indigo-500 rounded-2xl" onClick={() => navigate("/Estudiantes/InformacionAdicional", { state: { LDatos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: leccion.id_tema, IdLeccion: leccion.id_leccion, Tiempo: leccion.asignaciones[0].resultados[0].tiempo_transcurrido, PuntajeL: leccion.asignaciones[0].resultados[0].puntaje, Total: leccion.cantidad_preguntas, RandomNumber: "BrainEvaluacion.png" } } })}>
                                    <div className="bg-indigo-200 relative flex justify-center items-center w-full h-[250px] rounded-2xl before:absolute before:bottom-0 before:left-1/2 before:w-6 before:h-6 before:bg-transparent before:rounded-full before:shadow-before after:absolute after:bottom-[70px] after:left-0 after:w-6 after:h-6 after:bg-transparent after:rounded-full after:shadow-after bg-cover">
                                        <img
                                            src={NumeroLeccion(`./BrainEvaluacion.png`)}
                                            alt={leccion.nombre_leccion}
                                            className="h-full w-auto pb-20 rounded-2xl"
                                        />
                                    </div>

                                    <div className="relative w-full h-[190px] sm:h-[200px] bg-sky-500 rounded-2xl rounded-tl-none">
                                        <div className="absolute left-0 w-1/2 h-[80px] -top-[80px] bg-sky-500 border-t-[10px] border-r-[10px] border-blue-300 rounded-tr-3xl before:absolute before:w-6 before:h-6 before:bg-transparent before:rounded-full before:shadow-before2 after:absolute after:bottom-0 after:-right-6 after:w-6 after:h-6 after:bg-transparent after:rounded-full after:shadow-after2">
                                            <div className="w-full h-full flex justify-center items-center p-1">
                                                <h2 className="text-white capitalize font-semibold text-base text-center whitespace-pre-line sm:text-xl">{leccion.nombre_leccion}</h2>
                                            </div>
                                        </div>

                                        <div className="p-4 text-white flex flex-col justify-center">
                                            <div className="flex justify-center mb-2">
                                                <h3 className="font-semibold">Jarub´risi ut hoonal roksi:</h3>
                                            </div>

                                            <div className="flex flex-row justify-between mb-2">
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(0, 2)}</h4>
                                                    <h4>Hoonal</h4>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    <h4>:</h4>
                                                </div>
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(3, 5)}</h4>
                                                    <h4>Miin</h4>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    <h4>:</h4>
                                                </div>
                                                <div className="flex flex-col justify-center items-center w-full">
                                                    <h4>{leccion.asignaciones[0].resultados[0].tiempo_transcurrido.slice(-2)}</h4>
                                                    <h4>K´asal</h4>
                                                </div>
                                            </div>

                                            <div className="flex justify-center my-2">
                                                <h3 className="font-semibold">Jarub´risi hoonal:</h3>
                                            </div>

                                            <div className="container mx-auto">
                                                <div className="flex items-center justify-center">
                                                    <div className="relative w-full h-full bg-gray-200 rounded-lg">

                                                        <div className="bg-yellow-300 rounded-lg h-8 sm:h-10" style={{ width: `${(leccion.asignaciones[0].resultados[0].puntaje / leccion.cantidad_preguntas) * 100}%` }}>
                                                        </div>
                                                        <div className="absolute inset-y-0 inset-x-0">
                                                            <div className="flex items-center justify-center h-full w-full">
                                                                <h3 className="text-orange-500 font-semibold text-xl sm:text-2xl">{leccion.asignaciones[0].resultados[0].puntaje}/{leccion.cantidad_preguntas}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            )

                        );


                    })}

                </div>

            </div>

        </div>


    )
}

export default LectionHomeAdditional
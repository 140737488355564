import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { urlGradosDocentes, urlLeccionesEliminadasDocentes, urlListaLeccionesEliminadasDocentes, urlRestaurarLecciones, urlTemasDocentesSimples } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import Select from 'react-select';
import Swal from 'sweetalert2';

function RestoreLessons () {
    const location = useLocation();
    const access_Token = localStorage.getItem("accessToken");
    const decodadToken = jwtDecode(access_Token);
    const Id_Docente = location?.state?.codigoUsuario || decodadToken.Codigo_Usuario;


    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [lecciones, setLecciones] = useState([]);


    const [listaLecciones, setListaLecciones] = useState([])
    const [restoreLeccion, setRestoreLeccion] = useState({
        IdDocente: Id_Docente,
        IdGrado: null,
        IdTema : null,
        IdLeccion: null,
    });


    const [missingFields, setMissingFields] = useState([]);


    //Drop down list para grado
    useEffect(() => {
        const getGrados = async () => {
            try{
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch(error){
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);
    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setRestoreLeccion({
            ...restoreLeccion,
            [name]: value,
        })
    };


    //Drop down list para temas
    useEffect(() => {
        const getTemas = async () => {
            try{
                if(restoreLeccion.IdGrado !== null){
                    const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${restoreLeccion.IdGrado}`);
                    setTemas(responseTemas.data);
                }
                else {
                    setTemas([]);
                }
            } catch(error){
                console.log(error);
            }
        };
        getTemas();
    }, [restoreLeccion, Id_Docente]);
    const handleChangeDDLTemas = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;
        //setSelectedTemaId(selectedOption ? selectedOption.value : null);

        setRestoreLeccion({
            ...restoreLeccion,
            [name]: value,
        })
    };


    //Drop down list de lecciones
    useEffect(() => {
        const getLecciones = async () => {
            try{
                if(restoreLeccion.IdTema !== null){
                    const responseLecciones = await api.get(`${urlLeccionesEliminadasDocentes}?IdDocente=${Id_Docente}&IdGrado=${restoreLeccion.IdGrado}&IdTema=${restoreLeccion.IdTema}`);
                    setLecciones(responseLecciones.data);
                }
                else {
                    setLecciones([]);
                }
            } catch(error){
                console.log(error);
            }
        };
        getLecciones();
    }, [restoreLeccion, Id_Docente]);
    const handleChangeDDLLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setRestoreLeccion({
            ...restoreLeccion,
            [name]: value,
        });
    };


    useEffect(() => {
        const getLecciones = async () => {
            try{
                const response = await api.get(`${urlListaLeccionesEliminadasDocentes}?IdDocente=${Id_Docente}`);
                setListaLecciones(response.data);
            } catch(error){
                console.log(error);
            }
        };
        getLecciones();
    }, [Id_Docente]);









    const handleSubmit = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];
        
        if (!restoreLeccion.IdDocente) missingFieldsArray.push('IdDocente');
        if (!restoreLeccion.IdGrado) missingFieldsArray.push('IdGrado');
        if (!restoreLeccion.IdTema) missingFieldsArray.push('IdTema');
        if (!restoreLeccion.IdLeccion) missingFieldsArray.push('IdLeccion');

        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return;
        }

        try{
            const response = await api.patch(`${urlRestaurarLecciones}`,restoreLeccion);
            const responseDelete = await api.get(`${urlListaLeccionesEliminadasDocentes}?IdDocente=${Id_Docente}`);
            setListaLecciones(responseDelete.data);
            

            Swal.fire({
                icon: 'success',
                title: 'Restauración Exitosa',
                text: response.data.msg,
            });

            setRestoreLeccion({
                IdDocente: Id_Docente,
                IdGrado: null,
                IdTema : null,
                IdLeccion: null,

            });

        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al eliminar el tema. Por favor, inténtelo de nuevo.',
            });
        }
    };







    return (
    <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-sky-200'>
        <div className='flex justify-center py-5'>
            <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl'>Restaurar Lecciones</h1>
        </div>

        <div className="px-4 md:px-10 lg:px-20 my-5">
                <form onSubmit={handleSubmit}>
                    <div className=''>
                            <label className="text-base font-medium">Grado: </label>
                            <Select
                                options={grados}
                                className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                            ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                                classNamePrefix="select"
                                isSearchable={false} // Para deshabilitar la búsqueda
                                name='IdGrado'
                                placeholder="Seleccione un Grado"
                                onChange={handleChangeDDLGrados}
                                value={grados.find(grado => grado.value === restoreLeccion.IdGrado) || null}
                            />
                    </div>

                    <div className=''>
                            <label className="text-base font-medium">Tema: </label>
                            <Select
                                options={temas}
                                className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                            ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                                classNamePrefix="select"
                                isSearchable={false} // Para deshabilitar la búsqueda
                                name='IdTema'
                                placeholder="Seleccione un Tema"
                                onChange={handleChangeDDLTemas}
                                value={temas.find(tema => tema.value === restoreLeccion.IdTema) || null}
                            />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Lección: </label>
                        <Select
                            options={lecciones}
                            className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                        ${missingFields.includes('IdLeccion') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false}
                            name='IdLeccion'
                            placeholder="Seleccione la Lección"
                            onChange={handleChangeDDLLecciones}
                            value={lecciones.find(leccionU => leccionU.value === restoreLeccion.IdLeccion) || null}
                        />
                    </div>
                    
                    <div className="mt-4 w-full">
                        <button
                        className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
                        type="submit"
                        >
                        Restaurar Lección
                        </button>
                    </div>
                </form>
    
            </div>


            <div className='px-4 md:px-10 lg:px-20 mt-10'>
                <table className="bg-gray-100 min-w-full">
                    <thead className='bg-gray-200'>
                        <tr className='border-2 border-indigo-500'>
                            <th className="py-2 px-4 border-b">
                                <h2>
                                    Grado
                                </h2>
                            </th>
                            <th className="py-2 px-4 border-b">
                                <h2>
                                    Tema
                                </h2>
                            </th>
                            <th className="py-2 px-4 border-b">
                                Lección
                            </th>
                            <th className="py-2 px-4 border-b">
                                Estado
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-2 border-indigo-500'>

                        {
                            
                            Array.isArray(listaLecciones) && listaLecciones.map((listaLeccion)=>(
                                <tr key={listaLeccion.id_leccion}>
                                    <td className='className="py-2 px-4 border-b"'>
                                        <h3 className='w-full flex justify-center'>
                                            {listaLeccion.id_tema_tema.temas_x_grados[0].id_grado_grado.nombre_grado}
                                        </h3>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <h3 className='w-full flex justify-center'>
                                            {listaLeccion.id_tema_tema.id_tema}
                                        </h3>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <h3 className='w-full flex justify-center'>
                                            {listaLeccion.nombre_leccion}
                                        </h3>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <h3 className='flex justify-center bg-red-100 rounded-xl text-rose-700'>
                                            Eliminado
                                        </h3>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                
            </div>





    </div>
    )
}

export default RestoreLessons
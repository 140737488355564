import axios from "axios";



const api = axios.create({
    baseURL: "/https://sacsichiyo.com/api",
    headers: {
        "Content-Type": "application/json"
    }
});



api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);




api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const rToken = "refreshToken";
                const tExpirado = "tokenExpirado";

                const refreshTokenAntiguo = localStorage.getItem('refreshToken');
                const accesstokenExpirado = localStorage.getItem('accessToken');

                const dataTokens = {
                    [tExpirado]: accesstokenExpirado,
                    [rToken]: refreshTokenAntiguo
                }

                //const response = await axios.post('https://localhost:7066/api/Login/RecibirRefreshToken', { tokenExpirado: localStorage.getItem('accessToken'), refreshToken: localStorage.getItem('refreshToken') });
                const response = await axios.post('https://sacsichiyo.com/api/auth/renovation', dataTokens);

                const { access_Token, refresh_Token } = response.data;

                localStorage.setItem('accessToken', access_Token);
                localStorage.setItem("refreshToken", refresh_Token);

                originalRequest.headers.Authorization = `Bearer ${access_Token}`;
                return axios(originalRequest);
            } catch (error) {

                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                window.location.href = '/';


            }
        }

        return Promise.reject(error);
    }
);




export default api;
import React from 'react'
import { Information } from '../../../components/Student/Default/Information'

function InformationPage () {
  return (
    <div className="bg-gray-200 h-full">
      <Information/>
    </div>
  )
}

export default InformationPage
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode';

import NavbarStudent  from '../components/Student/NavbarStudent'
import StudentHomePage from '../pages/Student/Default/StudentHomePage'
import LectionHomePage from '../pages/Student/Default/LectionHomePage'
import InformationPage from '../pages/Student/Default/InformationPage'
import QuizPage from '../pages/Student/Default/QuizPage'
import StudentHomePageAdditional from '../pages/Student/Additional/StudentHomePageAdditional';
import LectionHomePageAdditional from '../pages/Student/Additional/LectionHomePageAdditional';
import InformationPageAdditional from '../pages/Student/Additional/InformationPageAdditional';
import QuizPageAdditional from '../pages/Student/Additional/QuizPageAdditional';

const useAuth = () => {
    const usuarioAT = localStorage.getItem('accessToken');
    const usuarioRT = localStorage.getItem('refreshToken');
    
    if(usuarioAT && usuarioRT){
        const decodedToken = jwtDecode(usuarioAT);
        const usuarioRol = decodedToken.Id_Rol;
        console.log(usuarioRol);
        if(!usuarioRol){
            return {
                auth: false,
                role: null,
            };
        }
        else{
            return {
                auth: true,
                role: usuarioRol
            };
        }
    }
    else{
        return {
            auth: false,
            role: null,
        };
    }
};



const ProtectedRoutesStudent = () => {
    const {role} = useAuth();


    if(role === 2){
        return (
            <Routes>
                <Route path='/' element={<NavbarStudent/>}>
                    <Route index element={<StudentHomePage />} />
                    <Route path="Lecciones" element={<LectionHomePage/>}/>
                    <Route path="Informacion" element={<InformationPage/>}/>
                    <Route path="Quiz" element={<QuizPage/>}/>
                    <Route path="TemasAdicionales" element={<StudentHomePageAdditional/>}/>
                    <Route path="LeccionesAdicionales" element={<LectionHomePageAdditional/>}/>
                    <Route path="InformacionAdicional" element={<InformationPageAdditional/>}/>
                    <Route path="QuizAdicional" element={<QuizPageAdditional/>}/>
                </Route>
            </Routes>
        );
    }
    else{
        return <Navigate to = "/" />
    }
};

export default ProtectedRoutesStudent
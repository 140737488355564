import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all";
import React from 'react';

export const ParticlesF = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadAll(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {

  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4, // Cantidad de partículas añadidas por clic
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF"],
        },
        links: {
          enable: false,
        },
        move: {
          direction: "bottom",
          enable: true,
          outModes: {
            default: "out",
          },
          speed: 5,
        },
        number: {
          density: {
            enable: true,
            area: 800,
          },
          value: 100, // Número inicial de partículas
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: ["circle", "square", "triangle", "polygon", "star"],
          options: {
            polygon: {
              sides: 5,
            },
          },
        },
        size: {
          value: { min: 2, max: 10 },
        },
        life: {
          duration: {
            sync: false,
            value: 13, // Duración de vida de las partículas en segundos
          },
          count: 1,
          delay: {
            random: {
              enable: false,
              minimumValue: 0,
            },
            value: 0,
          },
        },
        reduceDuplicates: true, // Elimina duplicados
      },
      detectRetina: true,
    }),
    [],
  );

  return <Particles id={props.id} particlesLoaded={particlesLoaded} options={options} />;
};

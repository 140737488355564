import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { urlListaPreguntasEliminadas, urlRestaurarPreguntas } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import { IoIosArrowBack } from 'react-icons/io';
import Swal from 'sweetalert2';

function RestoreQuestions ({
    setActivo,
    restorePreguntas
}) {

    const location = useLocation();
    const access_Token = localStorage.getItem("accessToken");
    const decodadToken = jwtDecode(access_Token);
    const Id_Docente = location?.state?.codigoUsuario || decodadToken.Codigo_Usuario;


    const [hoverOut, setHoverOut] = useState(false);
    const [formData, setFormData] = useState(null);
    const [preguntasEliminadas, setPreguntasEliminadas] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);


    const onResetQuestion = () => {
        setActivo(false);
    };


    useEffect(() => {
        const getDatosPreguntas = async () => {
            const responseDatos = await api.get(`${urlListaPreguntasEliminadas}?IdDocente=${restorePreguntas.IdDocente}&IdGrado=${restorePreguntas.IdGrado}&IdTema=${restorePreguntas.IdTema}&IdLeccion=${restorePreguntas.IdLeccion}`);
            const preguntas = responseDatos.data.map(pregunta => ({
                IdPregunta: pregunta.id_pregunta,
                PreguntaUsuario: pregunta.pregunta_usuario,
                RespuestasUsuario: pregunta.respuesta.map(resp => ({
                    IdRespuesta: resp.id_respuesta,
                    RespuestaUsuario: resp.respuesta_usuario,
                    CorrectaUsuario: resp.correcta_usuario === '1'
                })),
            }));
            
            setFormData({
                IdDocente: Id_Docente || restorePreguntas.IdDocente,
                IdGrado: restorePreguntas.IdGrado || responseDatos.data[0].id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado,
                IdTema: restorePreguntas.IdTema || responseDatos.data[0].id_leccion_leccione.id_tema_tema.id_tema,
                IdLeccion: restorePreguntas.IdLeccion || responseDatos.data[0].id_leccion_leccione.id_leccion,
                PreguntasUsuario: preguntas,
            });
        }
        getDatosPreguntas();
    }, [restorePreguntas, Id_Docente]);

    useEffect(() => {
        const getPreguntasEliminadas = async () => {
            const responseData = await api.get(`${urlListaPreguntasEliminadas}?IdDocente=${restorePreguntas.IdDocente}&IdGrado=${restorePreguntas.IdGrado}&IdTema=${restorePreguntas.IdTema}&IdLeccion=${restorePreguntas.IdLeccion}`);
            const preguntasE = responseData.data.map(preguntaE => ({
                IdPregunta: preguntaE.id_pregunta,
                PreguntaUsuario: preguntaE.pregunta_usuario,
                IdLeccion: preguntaE.id_leccion_leccione.id_leccion,
                NombreLeccion: preguntaE.id_leccion_leccione.nombre_leccion,
                IdTema: preguntaE.id_leccion_leccione.id_tema_tema.id_tema,
                NombreTema: preguntaE.id_leccion_leccione.id_tema_tema.nombre_tema,
                IdGrado: preguntaE.id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.id_grado,
                NombreGrado: preguntaE.id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.nombre_grado
            }));

            setPreguntasEliminadas(preguntasE);
            
            
        }
        getPreguntasEliminadas();
    }, [restorePreguntas, Id_Docente]);

    const handleQuestionSelection = (questionId) => {
        if (selectedQuestions.some(question => question.IdPregunta === questionId)) {
            // Si la pregunta ya está seleccionada, la eliminamos del array
            setSelectedQuestions(selectedQuestions.filter(question => question.IdPregunta !== questionId));
        } else {
            // Si la pregunta no está seleccionada, la agregamos al array
            setSelectedQuestions([...selectedQuestions, {
                IdDocente: restorePreguntas.IdDocente,
                IdGrado: restorePreguntas.IdGrado,
                IdTema: restorePreguntas.IdTema,
                IdLeccion: restorePreguntas.IdLeccion,
                IdPregunta: questionId
            }]);
        }
    };




    const handleClick = async (e) => {
        e.preventDefault();
        try{
            const response = await api.patch(`${urlRestaurarPreguntas}`,selectedQuestions);

            const restoreP = await api.get(`${urlListaPreguntasEliminadas}?IdDocente=${restorePreguntas.IdDocente}&IdGrado=${restorePreguntas.IdGrado}&IdTema=${restorePreguntas.IdTema}&IdLeccion=${restorePreguntas.IdLeccion}`);
            const preguntasE = restoreP.data.map(preguntaE => ({
                IdPregunta: preguntaE.id_pregunta,
                PreguntaUsuario: preguntaE.pregunta_usuario,
                IdLeccion: preguntaE.id_leccion_leccione.id_leccion,
                NombreLeccion: preguntaE.id_leccion_leccione.nombre_leccion,
                IdTema: preguntaE.id_leccion_leccione.id_tema_tema.id_tema,
                NombreTema: preguntaE.id_leccion_leccione.id_tema_tema.nombre_tema,
                IdGrado: preguntaE.id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.id_grado,
                NombreGrado: preguntaE.id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.nombre_grado
            }));

            setPreguntasEliminadas(preguntasE);


            const responseDatosA = await api.get(`${urlListaPreguntasEliminadas}?IdDocente=${restorePreguntas.IdDocente}&IdGrado=${restorePreguntas.IdGrado}&IdTema=${restorePreguntas.IdTema}&IdLeccion=${restorePreguntas.IdLeccion}`);
            const preguntasA = responseDatosA.data.map(pregunta => ({
                    IdPregunta: pregunta.id_pregunta,
                    PreguntaUsuario: pregunta.pregunta_usuario,
                    RespuestasUsuario: pregunta.respuesta.map(resp => ({
                    IdRespuesta: resp.id_respuesta,
                    RespuestaUsuario: resp.respuesta_usuario,
                    CorrectaUsuario: resp.correcta_usuario === '1'
                })),
            }));
            
            setFormData({
                IdDocente: Id_Docente || restorePreguntas.IdDocente,
                IdGrado: restorePreguntas.IdGrado || responseDatosA.data[0].id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado,
                IdTema: restorePreguntas.IdTema || responseDatosA.data[0].id_leccion_leccione.id_tema_tema.id_tema,
                IdLeccion: restorePreguntas.IdLeccion || responseDatosA.data[0].id_leccion_leccione.id_leccion,
                PreguntasUsuario: preguntasA,
            });

            setSelectedQuestions([]);
            console.log(selectedQuestions);
            Swal.fire({
                icon: 'success',
                title: 'Restauración Exitosa',
                text: response.data.msg,
            });
        } catch (error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al modificar las preguntas. Por favor, inténtelo nuevamente.'
            });
            console.log(error);
        }
    };












    return (
        <>
            <div className="w-10 py-2 lg:py-5" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => {setHoverOut(false)}}>
                <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={onResetQuestion}>
                    <IoIosArrowBack className='font-bold text-lg' />
                    <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Abandonar</h3>
                </button>
            </div>

            <div className='flex justify-center py-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl'>Restaurar Preguntas</h1>
            </div>


            <form className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6' onSubmit={handleClick}>
                {
                    formData && formData.PreguntasUsuario.map((question) => (
                        <div key={question.IdPregunta} className='mb-10 lg:mb-0 px-2 pt-2 pb-4 lg:px-4 lg:pt-4 lg:pb-6 lg:col-span-2 rounded-xl bg-sky-300'>
                            <div className="mb-2">
                                <label className='text-sm font-medium sm:text-base'>Pregunta: </label>
                            </div>

                            <div className="mb-2">
                                <input
                                    className={`w-full p-2 text-sm sm:text-base lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                    `}
                                type="text"
                                name="PreguntaUsuario"
                                value={question.PreguntaUsuario}
                                readOnly
                                />
                            </div>


                            <div className='mb-4 px-1 py-2 lg:p-4 grid grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-6 rounded-xl bg-orange-200'>
                                <label className='text-sm font-medium sm:text-base lg:col-span-12'>Respuestas: </label>
                                {
                                    question.RespuestasUsuario.map((answer) => (
                                    <div key={answer.IdRespuesta} className='lg:col-span-6'>
                                        <input
                                            className={`w-full p-2 text-sm sm:text-base lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                            `}
                                            type="text"
                                            name="RespuestaUsuario"
                                            value={answer.RespuestaUsuario}
                                            readOnly
                                        />
                                    </div>

                                    ))
                                }
                            </div>

                            <div>
                                <input
                                    id={`question-${question.IdPregunta}`}
                                    className={` checkbox`}
                                    type="checkbox"
                                    checked={selectedQuestions.some(q => q.IdPregunta === question.IdPregunta)}
                                    onChange={() => handleQuestionSelection(question.IdPregunta)}
                                />
                                <label htmlFor={`question-${question.IdPregunta}`} className="ml-2">Seleccionar</label>
                            </div>

                        </div>

                    ))
                }




                <div className='lg:col-span-2 mb-5 sm:mt-5 sm:mb-10'>
                    <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-green-500 text-white text-lg font-bold px-4 hover:bg-green-600' type="submit">
                        Restaurar Preguntas
                    </button>
                </div>
            </form>


            <h2 className="text-base font-medium my-5">Preguntas Eliminadas: </h2>

            <table className="bg-gray-100 min-w-full mt-5 mb-10">
                <thead className='bg-gray-200'>
                    <tr className='border-2 border-indigo-500'>
                        <th className="py-2 px-4 border-b">
                            <h2>
                                Grado
                            </h2>
                        </th>
                        <th className="py-2 px-4 border-b">
                            <h2>
                                Tema
                            </h2>
                        </th>
                        <th className="py-2 px-4 border-b">
                            <h2>
                                Lección
                            </h2>
                        </th>
                        <th className="py-2 px-4 border-b">
                            <h2>
                                Pregunta
                            </h2>
                        </th>
                        <th className="py-2 px-4 border-b">
                            Estado
                        </th>
                    </tr>
                </thead>
                <tbody className='border-2 border-indigo-500'>

                    {
                        preguntasEliminadas && preguntasEliminadas.map((questionD)=>(
                            <tr key={questionD.IdPregunta}>
                                <td className='className="py-2 px-4 border-b"'>
                                    <h3 className='w-full flex justify-center'>
                                        {questionD.NombreGrado}
                                    </h3>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <h3 className='w-full flex justify-center'>
                                        {questionD.NombreTema}
                                    </h3>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <h3 className='w-full flex justify-center'>
                                        {questionD.NombreLeccion}
                                    </h3>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <h3 className='w-full flex justify-center'>
                                        {questionD.PreguntaUsuario}
                                    </h3>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <h3 className='flex justify-center bg-red-100 rounded-xl text-rose-700'>
                                        Eliminado
                                    </h3>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>




        </>
    )
}

export default RestoreQuestions
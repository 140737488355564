import React from 'react'
import StudentProgress from '../components/Teacher/StudentProgress';

const StudentProgressPage = () => {
  return (
    <StudentProgress/>
    
  )
}

export default StudentProgressPage
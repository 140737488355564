import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import api from '../../helpers/Api';
import { urlGradosDocentes, urlListaResultadosAd, urlTemasDocentesSimples } from '../../helpers/endpoints';
import Select from 'react-select';
import { BsChevronDown, BsClipboardCheckFill } from 'react-icons/bs';
import { PiStudent } from 'react-icons/pi';

  function StudentProgress () {
    const location = useLocation();
    const access_Token = localStorage.getItem("accessToken");
    const decodadToken = jwtDecode(access_Token);
    const Id_Docente = location?.state?.codigoUsuario || decodadToken.Codigo_Usuario;

    const [openStudent, setOpenStudent] = useState(null);


    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [restoreTema, setRestoreTema] = useState({
      IdTema : null,
      IdGrado: null,
      IdDocente: Id_Docente,
    });


    const [resultadosEstudiantes, setResultadosEstudiantes] = useState([]);

  useEffect(() => {
    const getGrados = async () => {
        try{
            const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
            setGrados(responseGrados.data);
        } catch(error){
            console.log(error);
        }
    };
    getGrados();
  }, [Id_Docente]);


  const handleChangeDDLGrados = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;

    setRestoreTema({
        ...restoreTema,
        [name]: value,
    })
  };


  useEffect(() => {
    const getTemas = async () => {
        try{
            if(restoreTema.IdGrado !== null){
                const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${restoreTema.IdGrado}`);
                setTemas(responseTemas.data);
            }
            else {
                setTemas([]);
            }
        } catch(error){
            console.log(error);
        }
    };
    getTemas();
  }, [restoreTema, Id_Docente]);


  const handleChangeDDLTemas = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;

    setRestoreTema({
        ...restoreTema,
        [name]: value,
    })
  };



    const handleSubmit = async (e) => {
      e.preventDefault();
      try{
        const responseLista = await api.post(
          `${urlListaResultadosAd}`,
          restoreTema
        );
        setResultadosEstudiantes(responseLista.data);
      }
      catch (error){
        console.log(error);
      }
    };
    return (
      <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-sky-200'>
        <div className='px-4 md:px-10 lg:px-20 mb-5'>
          <h2 className='font-comforter text-lg sm:text-2xl md:text-3xl text-center py-5'>Avance de Alumnos</h2>
          <form onSubmit={handleSubmit}>
            <div className=''>
                  <label className="text-base font-medium">Grado: </label>
                  <Select
                      options={grados}
                      className="w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded"
                      classNamePrefix="select"
                      isSearchable={false} // Para deshabilitar la búsqueda
                      name='IdGrado'
                      placeholder="Seleccione un Grado"
                      onChange={handleChangeDDLGrados}
                      value={grados.find(grado => grado.value === restoreTema.IdGrado) || null}
                  />

            </div>
            <div className=''>
              <label className="text-base font-medium">Tema: </label>
              <Select
                  options={temas}
                  className="w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded"
                  classNamePrefix="select"
                  isSearchable={false} // Para deshabilitar la búsqueda
                  name='IdTema'
                  placeholder="Seleccione un Tema"
                  onChange={handleChangeDDLTemas}
                  value={temas.find(tema => tema.value === restoreTema.IdTema) || null}
              />
            </div>
            <div className="mt-4 w-full">
              <button
              className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
              type="submit"
              >
              Buscar Lecciones
              </button>
            </div>
          </form>
        </div>

        {
          resultadosEstudiantes.length === 0 ?
          (
            <div className='bg-orange-100 w-full'>
              <h4>
                Vacío
              </h4>
            </div>
          ) :
          (

            <div className='h-auto my-2 mx-1 px-1 md:px-10 lg:px-20 bg-orange-100 py-2'>
              <div className='mb-4'>
                <h2 className='text-center text-xl font-comforter'>
                  {resultadosEstudiantes[0].asignaciones[0].id_leccion_leccione.id_tema_tema.nombre_tema && resultadosEstudiantes[0].asignaciones[0].id_leccion_leccione.id_tema_tema.nombre_tema}
                </h2>
              </div>

              {
                Array.isArray(resultadosEstudiantes) && resultadosEstudiantes.map((estudiante) => (
                  <div key={estudiante.codigo_estudiante} className='bg-orange-300 rounded-md mt-2'>
                    <div className={`text-sm flex items-center justify-between cursor-pointer hover:bg-orange-400 rounded-md ${openStudent === estudiante.codigo_estudiante && "bg-orange-400"}`} onClick={() => setOpenStudent(openStudent === estudiante.codigo_estudiante ? null : estudiante.codigo_estudiante)}>
                      <div className={`w-full py-3 flex hover:text-white ${openStudent === estudiante.codigo_estudiante ? "text-white" : ""}`}>
                        <div className='w-3/4 flex'>
                          <span className="w-1/4 flex justify-center text-2xl">
                            <PiStudent />
                          </span>
                          <span className="w-3/4 text-center text-base font-medium">
                            {estudiante.Primer_nombre} {estudiante.primer_apellido}
                          </span>
                        </div>
                        <div className='w-1/4 flex justify-center'>
                          <span className="flex items-center text-2xl">
                            <BsClipboardCheckFill />
                          </span>
                          <span className="text-base font-medium flex-1">
                            {/* {resultadosEstudiantes[0].resultados[0].id_leccion_leccione.id_tema_tema.nombre_tema && resultadosEstudiantes[0].resultados[0].id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.nombre_grado} */}
                          </span>
                        </div>
                      </div>
                      <div>
                        <BsChevronDown className={`${openStudent === estudiante.codigo_estudiante ? "" : "rotate-180"} mr-5 rounded-md bg-white text-3xl p-2`}/>
                      </div>
                    </div>
                    
                    <ul className={`${openStudent === estudiante.codigo_estudiante ? "" : "hidden"}`}>
                      {
                        Array.isArray(estudiante.asignaciones) && estudiante.asignaciones.map((asignacion, i) => (
                          <div key={i} className='px-2 py-2'>
                            <li className='bg-red-500 rounded-md flex flex-col items-center justify-center text-white'>
                              <h3>Lección: {asignacion.id_leccion_leccione.nombre_leccion}</h3>
                              <h4>{asignacion.resultados[0].puntaje}/{estudiante.cantidad_preguntas}</h4>
                              <h4>{asignacion.resultados[0].tiempo_transcurrido}</h4>
                            </li>
                          </div>
                        ))
                      }
                    </ul>


                  </div>

                ))
              }



            </div>

          )
        }





      </div>
    )
  }

export default StudentProgress
import React from 'react'
import { Route, Routes } from 'react-router-dom'

// import LoginPage from '../pages/LoginPage'


// import ProtectedRoutes from '../components/Others/ProtectedRoutes'
import PublicRoutes from './PublicRoutes'

import ProtectedRoutesTeacher from './ProtectedRoutesTeacher'
import ProtectedRoutesStudent from './ProtectedRoutesStudent'

export const AppRouter = () => {
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [userRol, setUserRol] = useState('');

  // const renderNav = () => {
  //   if (userRol === 2) {
  //     return (
  //       <NavbarStudent>
  //         <Routes>
  //             <Route path="/TemasEstudiantes" element={<StudentHomePage/>}/>
  //             <Route path="/LeccionesEstudiantes" element={<LectionHomePage/>}/>
  //             <Route path="/Informacion" element={<InformationPage/>}/>
  //             <Route path="/Quiz" element={<QuizPage/>}/>
  //         </Routes>
  //       </NavbarStudent>
  //     );
  //   }
  //   else if (userRol === 1) {
  //     return(
  //       <Navbar>
  //         <Routes>
  //             {/*Esta ruta es para docentes */}
  //             <Route path="/Docentes" element={<TeacherHomePage/>}/>
  //             <Route path="/HomeDocentes" element={<HomeTeacher1/>}/>
  //             <Route path="/CreateTema" element={<CreateTema1/>}/>
  //         </Routes>
  //       </Navbar>
  //     );
  //   }
  //   else{
  //     return <Navigate to="/"/>;
  //   }
  // }




  return (
    <>
      <Routes>
        <Route path='/*' element={<PublicRoutes />}>
          {/* <Route index element={<LoginPage />}/> */}
        </Route>

        <Route path='/Estudiantes/*' element={<ProtectedRoutesStudent/>}>
        </Route>

        <Route path='/Docentes/*' element={<ProtectedRoutesTeacher/>}>
        </Route>
      </Routes>
    </>
  )
}

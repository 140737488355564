// const baseURL = process.env.REACT_APP_API_URL;
const baseURL = 'https://sacsichiyo.com';


export const urlLogin = `${baseURL}/api/auth/login`;
export const urlLogout = `${baseURL}/api/auth/logout`;

/* |||||||||||||||||||||||||||||||||Ingresos de docentes||||||||||||||||||||||||| */
/* Ingresar Temas */
export const urlCreateTemas = `${baseURL}/api/ingresar/temas`;
/* Ingresar Lecciones */
export const urlCreateLecciones = `${baseURL}/api/ingresar/lecciones`;
/* Ingresar Preguntas */
export const urlCreatePreguntas = `${baseURL}/api/ingresar/preguntas`;


/* |||||||||||||||||||||||||||Modificaciones de Docentes||||||||||||||||||||||||||| */
/* --------------------------------Modificar Temas--------------------------------- */
export const urlModificarTemas = `${baseURL}/api/modificar/temas`;
/* Modificar Lecciones */
export const urlLista_Leccion_Modificar = `${baseURL}/api/auxiliares/listaLeccionModificada`;
export const urlModificarLecciones = `${baseURL}/api/modificar/lecciones`;
/* Modificar Preguntas y Respuestas */
export const urlLista_Pregunta_Modificar = `${baseURL}/api/auxiliares/listaPreguntaModificada`;
export const urlModificarPreguntas = `${baseURL}/api/modificar/preguntas`;


/* ||||||||||||||||||||||||||Eliminaciones de Docentes||||||||||||||||||||||||||||| */
/* ----------------------------------Eliminar Temas-------------------------------- */
export const urlEliminarTemas = `${baseURL}/api/eliminar/temas`;
/* Tabla de temas Desactivados para docentes */
export const urlListaTemasEliminadosDocentes = `${baseURL}/api/auxiliares/listaTemasEliminados`;
/* --------------------------------Eliminar Lecciones-------------------------------- */
export const urlEliminarLecciones = `${baseURL}/api/eliminar/lecciones`;
/* Tabla de lecciones Desactivados para docentes */
export const urlListaLeccionesEliminadasDocentes = `${baseURL}/api/auxiliares/listaLeccionesEliminadas`;
/* ----------------------------------Eliminar Preguntas------------------------------- */
/* Tabla de preguntas Eliminadas (Para docentes) */
export const urlEliminarPreguntas = `${baseURL}/api/eliminar/preguntas`;
export const urlListaPreguntasEliminadas = `${baseURL}/api/auxiliares/listaPreguntaEliminada`;





/* ||||||||||||||||||||||||||||||||Restauraciones de Docentes|||||||||||||||||||||||||| */
/* --------------------------------------Restaurar Temas------------------------------- */
export const urlRestaurarTemas = `${baseURL}/api/restaurar/temas`;
/* --------------------------------------Restaurar Lecciones------------------------------- */
export const urlRestaurarLecciones = `${baseURL}/api/restaurar/lecciones`;
/* --------------------------------------Restaurar Preguntas------------------------------- */
export const urlRestaurarPreguntas = `${baseURL}/api/restaurar/preguntas`;


/* |||||||||||||||Lista de resultados de los estudiantes que verán los docentes|||||||||| */
export const urlListaResultados = `${baseURL}/api/auxiliares/listaResultados`;
export const urlListaResultadosAd = `${baseURL}/api/auxiliares/listaResultadosAdicionales`;
/* Tabla de temas Activados para docentes */
export const urlListaTemasDocentes = `${baseURL}/api/auxiliares/listaTemas`;



/* Drop down list de grados para docentes */
export const urlGradosDocentes = `${baseURL}/api/auxiliares/grados`;
/* Drop down list de temas para docentes */
export const urlTemasDocentes = `${baseURL}/api/auxiliares/temas`;
/* Drop down list de temas para docentes sin la relacion con la tabla lecciones */
export const urlTemasDocentesSimples = `${baseURL}/api/auxiliares/temasSimples`;
/* Drop down list de temas eliminados para docentes */
export const urlTemasEliminadosDocentes = `${baseURL}/api/auxiliares/temasEliminados`;
/* Drop down list de lecciones para docentes */
export const urlDDLLecciones = `${baseURL}/api/auxiliares/lecciones`;
/* Drop down list de lecciones eliminadas para docentes */
export const urlLeccionesEliminadasDocentes = `${baseURL}/api/auxiliares/leccionesEliminadas`;
/* Drop down list de tipos de lecciones para docentes */
export const urlTiposLecciones = `${baseURL}/api/auxiliares/tipoLeccion`;







/* Lista de temas predeterminados del sistema */
export const urlTemas = `${baseURL}/api/temas/lista`;
/* Lista de temas adicionales del sistema */
export const urlTemasAdicionales = `${baseURL}/api/temas/listaAdicionales`;
/* Lista de lecciones predeterminadas de los temas predeterminados del sistema */
export const urlLecciones = `${baseURL}/api/lecciones/lista`;
/* Lista de lecciones adicionales de los temas adicionales del sistema */
export const urlLeccionesAdicionales = `${baseURL}/api/lecciones/listaAdicionales`;
/* Lista de información predeterminadas de las lecciones predeterminados del sistema */
export const urlInformacion = `${baseURL}/api/lecciones/informacion`;
/* Lista de información adicional de las lecciones adicionales del sistema */
export const urlInformacionAdicional = `${baseURL}/api/lecciones/informacionAdicional`;
/* Lista de preguntas predeterminadas de las lecciones predeterminados del sistema */
export const urlQuiz = `${baseURL}/api/Quiz/evaluacion`;
/* Lista de preguntas adicionales de las lecciones adicionales del sistema */
export const urlQuizAdicional = `${baseURL}/api/Quiz/evaluacionAdicional`;
/* Envío del resultados predeterminados de las lecciones predeterminadas del sistema */
export const urlResultados = `${baseURL}/api/Quiz/resultados`;
/* Envío del resultados adicionales de las lecciones adicionales del sistema */
export const urlResultadosAdicionales = `${baseURL}/api/Quiz/resultadosAdicionales`;
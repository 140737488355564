import React from "react";
import HomeStudent from "../../../components/Student/Default/HomeStudent";



const StudentHomePage = () => {
  return (
      <HomeStudent/>
  )
}
export default StudentHomePage;
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../../helpers/Api';
import { urlLista_Pregunta_Modificar, urlModificarPreguntas } from '../../../helpers/endpoints';

function UpdateQuestions({
    setActivo,
    updatePreguntas
}) {
    const location = useLocation();
    const Id_Docente = location?.state?.codigoUsuario || "Usuario";

    const [hoverOut, setHoverOut] = useState(false);
    const [formData, setFormData] = useState(null);
    const [missingFields, setMissingFields] = useState([]);

    const onResetLesson = () => {
        setActivo(false);
    };

    useEffect(() => {
        const getDatosModificaciones = async () => {
            try {
                const responseDatos = await api.get(`${urlLista_Pregunta_Modificar}?IdDocente=${updatePreguntas.IdDocente}&IdGrado=${updatePreguntas.IdGrado}&IdTema=${updatePreguntas.IdTema}&IdLeccion=${updatePreguntas.IdLeccion}`);
                const preguntas = responseDatos.data.map(pregunta => ({
                    IdPregunta: pregunta.id_pregunta,
                    PreguntaUsuario: pregunta.pregunta_usuario,
                    RespuestasUsuario: pregunta.respuesta.map(respuesta => ({
                        IdRespuesta: respuesta.id_respuesta,
                        RespuestaUsuario: respuesta.respuesta_usuario,
                        CorrectaUsuario: respuesta.correcta_usuario === '1'
                    })),
                }));

                setFormData({
                    IdDocente: Id_Docente || updatePreguntas.IdDocente,
                    IdGrado: updatePreguntas.IdGrado || responseDatos.data[0].id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado,
                    IdTema: updatePreguntas.IdTema || responseDatos.data[0].id_leccion_leccione.id_tema_tema.id_tema,
                    IdLeccion: updatePreguntas.IdLeccion || responseDatos.data[0].id_leccion_leccione.id_leccion,
                    PreguntasUsuario: preguntas,
                });
            } catch (error) {
                console.log(error);
            }
        }
        getDatosModificaciones();
    }, [updatePreguntas, Id_Docente])

    const handleExampleChange = (questionIndex, answerIndex, fieldName, value) => {
        setFormData(prevData => {
            const updatedExamples = [...prevData.PreguntasUsuario];
            if (fieldName === 'PreguntaUsuario') {
                updatedExamples[questionIndex] = {
                    ...updatedExamples[questionIndex],
                    [fieldName]: value
                };
            } else {
                updatedExamples[questionIndex] = {
                    ...updatedExamples[questionIndex],
                    RespuestasUsuario: updatedExamples[questionIndex].RespuestasUsuario.map((answer, index) => {
                        if (index === answerIndex) {
                            return {
                                ...answer,
                                [fieldName]: value
                            };
                        }
                        return answer;
                    })
                };
            }
            return { ...prevData, PreguntasUsuario: updatedExamples };
        });
    };

    const handleSelectCorrectAnswer = (questionIndex, correctAnswerIndex) => {
        setFormData(prevData => {
            const updatedPreguntas = [...prevData.PreguntasUsuario];
            updatedPreguntas[questionIndex].RespuestasUsuario.forEach((answer, index) => {
                answer.CorrectaUsuario = index === correctAnswerIndex;
            });
            return { ...prevData, PreguntasUsuario: updatedPreguntas };
        });
    };

    const handleModificarClick = async (e) => {
        e.preventDefault();
        
        const missingFieldsArray = formData.PreguntasUsuario.reduce((acc, question, questionIndex) => {
            if (!question.PreguntaUsuario.trim()) {
                acc.push({ questionIndex, answerIndex: null });
            }
            question.RespuestasUsuario.forEach((answer, answerIndex) => {
                if (!answer.RespuestaUsuario.trim()) {
                    acc.push({ questionIndex, answerIndex });
                }
            });
            return acc;
        }, []);



        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return;
        }

        try {
            const responsePreguntas = await api.put(`${urlModificarPreguntas}`,formData);
            Swal.fire({
                icon: 'success',
                title: 'Actualización Completada',
                text: responsePreguntas.data.message
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al modificar las preguntas. Por favor, inténtelo nuevamente.'
            });
        }
    };

    return (
        <>
            <div className="w-10 py-2 lg:py-5" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => { setHoverOut(false) }}>
                <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={onResetLesson}>
                    <IoIosArrowBack className='font-bold text-lg' />
                    <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Abandonar</h3>
                </button>
            </div>

            <div className='flex justify-center pb-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl'>Modificar Preguntas y Respuestas</h1>
            </div>

            <form className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6' onSubmit={handleModificarClick}>
                {formData && formData.PreguntasUsuario.map((question, questionIndex) => (
                    <div key={question.IdPregunta} className='mb-10 lg:mb-0 px-2 pt-2 pb-4 lg:px-4 lg:pt-4 lg:pb-6 lg:col-span-2 rounded-xl bg-sky-300'>
                        <div className="mb-2">
                            <label className='text-sm font-medium sm:text-base'>Pregunta: </label>
                        </div>
                        <div className='mb-2'>
                            <input
                                className={`w-full p-2 text-sm sm:text-base lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                            ${missingFields.some(field => field.questionIndex === questionIndex && field.answerIndex === null) ? 'border-red-500' : ''}`}
                                type="text"
                                name="PreguntaUsuario"
                                value={question?.PreguntaUsuario || ''}
                                onChange={(e) => handleExampleChange(questionIndex, null, 'PreguntaUsuario', e.target.value)}
                            />
                        </div>

                        <div className='mb-4 px-1 py-2 lg:p-4 grid grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-6 rounded-xl bg-orange-200'>
                            <label className='text-sm font-medium sm:text-base lg:col-span-12'>Respuestas: </label>
                            {question.RespuestasUsuario.map((answer, answerIndex) => (
                                <div key={answer.IdRespuesta} className='lg:col-span-6'>
                                    <input
                                        className={`w-full p-2 text-sm sm:text-base lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                                    ${missingFields.some(field => field.questionIndex === questionIndex && field.answerIndex === answerIndex) ? 'border-red-500' : ''}`}
                                        type="text"
                                        name="RespuestaUsuario"
                                        value={answer?.RespuestaUsuario || ''}
                                        onChange={(e) => handleExampleChange(questionIndex, answerIndex, 'RespuestaUsuario', e.target.value)}
                                    />
                                    <label className="inline-flex items-center py-2">
                                        <input
                                            type="radio"
                                            name={`CorrectaUsuario-${questionIndex}`}
                                            checked={answer?.CorrectaUsuario}
                                            onChange={() => handleSelectCorrectAnswer(questionIndex, answerIndex)}
                                            className='w-5 h-5'
                                        />
                                        <label className='ml-2'>Correcta</label>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className='lg:col-span-2 mb-5 sm:mt-5 sm:mb-10'>
                    <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-green-500 text-white text-lg font-bold px-4 hover:bg-green-600' type="submit">
                        Modificar
                    </button>
                </div>
            </form>
        </>
    )
}

export default UpdateQuestions;

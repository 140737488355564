import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { jwtDecode } from "jwt-decode";

import { BsChevronDown } from 'react-icons/bs';
import { GiHamburgerMenu, GiNotebook } from "react-icons/gi";
import { FaClipboardCheck } from "react-icons/fa6";
import { GoPlusCircle } from "react-icons/go";
import { RxUpdate } from "react-icons/rx";
import { MdDeleteForever } from "react-icons/md";
import { FaTools } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Outlet } from "react-router-dom";
import axios from 'axios';
import { urlLogout } from '../../helpers/endpoints';

function NavbarTeacher() {
  const navigate = useNavigate();
  
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(true);
  const [subMenuOpenCreate, setSubMenuOpenCreate] = useState(true);
  const [subMenuOpenUpdate, setSubMenuOpenUpdate] = useState(true);
  const [subMenuOpenDelete, setSubMenuOpenDelete] = useState(true);

  const access_Token = localStorage.getItem('accessToken');
  const refresh_Token = localStorage.getItem('refreshToken');

  const decodadToken = jwtDecode(access_Token);
  const codigoUsuario = decodadToken.Codigo_Usuario;
  const primerNombre = decodadToken.Primer_Nombre;
  const primerAppellido = decodadToken.Primer_Apellido;

  const primeraLetra = [primerNombre.charAt(0),primerAppellido.charAt(0)];

  const logout = async () => {
    try{
      const response = await axios.post(`${urlLogout}`,{accessToken: access_Token, refreshToken: refresh_Token});
      
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate('/');
    }
  catch(error){

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate('/');
  }

  }

  return (

    <div className="flex">
      
      <div className={`bg-red-500 min-h-screen py-5 pl-5 pt-8 ${open ? "w-72" : "w-20"} duration-300 relative`}>
        <div className='p-1 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 absolute right-5 top-8' onClick={() => setOpen(!open)}>
          <GiHamburgerMenu className={`bg-white text-3xl rounded-full`}/>
        </div>
        

        <Link to="/Docentes">
          <div className={`pb-8 bg-transparent flex items-center justify-between`}>
            <h1 className={`flex text-3xl font-extrabold text-white ${!open && "hidden"}`}>Sacsi Chiyo</h1>
          </div>
        </Link>
        



        <div className={`bg-transparent flex items-center ${!open && "pt-10"}`}>
          <div className='bg-indigo-400 rounded-lg mr-2'>
            <div className='text-white px-3 py-2'>{primeraLetra}</div>
          </div>
          <div className={`${!open && "hidden"}`}>
            <h1 className={`text-xl font-medium text-white`}>{primerNombre} {primerAppellido}</h1>
            <h1 className={`text-white`}>Docente</h1>
          </div>
        </div>

        
        <Link to="/Docentes" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes'?'text-indigo-600 bg-sky-200 rounded-l-3xl mt-2 p-2 text-sm flex items-center gap-x-4 cursor-pointer':'text-gray-50 mt-2 p-2 text-sm flex items-center gap-x-4 cursor-pointer hover:bg-sky-400 '}>
                <span className='text-2xl block float-left'>
                  <GiNotebook/>
                </span>
                <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
                  Avance de Alumnos
                </span>
            </div>
        </Link>

        <Link to="/Docentes/AvanceLeccion" state={{ codigoUsuario }}>

            <div className={location.pathname==='/Docentes/AvanceLeccion'?'text-indigo-600 bg-sky-200 rounded-l-3xl mt-2 p-2 text-sm flex items-center gap-x-4 cursor-pointer':'text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md mt-2'}>
                <span className='text-2xl block float-left'>
                  <FaClipboardCheck/>
                </span>
                <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
                  Avance de Alumnos
                </span>
            </div>

        </Link>


        <div className={`text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md mt-2`} onClick={()=> setSubMenuOpenCreate(!subMenuOpenCreate)}>
            <span className='text-2xl block float-left'>
              <GoPlusCircle/>
            </span>
            <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
              Ingresar
            </span>
            <BsChevronDown className={`${subMenuOpenCreate && "rotate-180"}`}/>
        </div>
        <ul className={`${subMenuOpenCreate && "hidden"} ${!open && "hidden"}`}>
          <Link to= "/Docentes/CrearTemas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/CrearTemas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Temas
              </li>
            </div>
          </Link>
          <Link to="/Docentes/CrearLecciones" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/CrearLecciones'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Lecciones
              </li>
            </div>
          </Link>
          <Link to="/Docentes/CrearPreguntas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/CrearPreguntas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Preguntas
              </li>
            </div>
          </Link>
        </ul>



        <div className={`text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md mt-2`}  onClick={()=> setSubMenuOpenUpdate(!subMenuOpenUpdate)}>
            <span className='text-2xl block float-left'>
              <RxUpdate />
            </span>
            <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
              Modificar
            </span>
            <BsChevronDown className={`${subMenuOpenUpdate && "rotate-180"}`}/>
        </div>
        <ul className={`${subMenuOpenUpdate && "hidden"} ${!open && "hidden"}`}>
          <Link to="/Docentes/ModificarTemas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/ModificarTemas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Temas
              </li>
            </div>
          </Link>
          <Link to="/Docentes/ModificarLecciones" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/ModificarLecciones'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Lecciones
              </li>
            </div>
          </Link>
          <Link to="/Docentes/ModificarPreguntas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/ModificarPreguntas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Preguntas
              </li>
            </div>
          </Link>
        </ul>



        <div className={`text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md mt-2`} onClick={()=> setSubMenuOpenDelete(!subMenuOpenDelete)}>
            <span className='text-2xl block float-left'>
              <MdDeleteForever />
            </span>
            <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
              Eliminar
            </span>
            <BsChevronDown className={`${subMenuOpenDelete && "rotate-180"}`}/>
        </div>
        <ul className={`${subMenuOpenDelete && "hidden"} ${!open && "hidden"}`}>
          <Link to="/Docentes/EliminarTemas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/EliminarTemas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Temas
              </li>
            </div>
          </Link>
          <Link to="/Docentes/EliminarLecciones" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/EliminarLecciones'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Lecciones
              </li>
            </div>
          </Link>
          <Link to="/Docentes/EliminarPreguntas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/EliminarPreguntas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Preguntas
              </li>
            </div>
          </Link>
        </ul>



        <div className={`text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md mt-2`} onClick={()=> setSubMenuOpen(!subMenuOpen)}>
          <span className='text-2xl block float-left'>
            <FaTools />
          </span>
          <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
            Restaurar
          </span>
          <BsChevronDown className={`${subMenuOpen && "rotate-180"}`}/>
        </div>
        <ul className={`${subMenuOpen && "hidden"} ${!open && "hidden"}`}>
          <Link to="/Docentes/RestaurarTemas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/RestaurarTemas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Temas
              </li>
            </div>
          </Link>
          <Link to="/Docentes/RestaurarLecciones" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/RestaurarLecciones'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Lecciones
              </li>
            </div>
          </Link>
          <Link to="/Docentes/RestaurarPreguntas" state={{ codigoUsuario }}>
            <div className={location.pathname==='/Docentes/RestaurarPreguntas'?'rounded-l-3xl bg-blue-100':''}>
              <li className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-red-200 rounded-md px-5'>
                Preguntas
              </li>
            </div>
          </Link>
        </ul>


        <div className={`text-gray-50 text-sm flex items-center gap-x-4 cursor-pointer p-2 mt-8 hover:bg-red-200 rounded-md`} onClick={logout}>
            <span className='text-2xl block float-left'>
              <CiLogout />
            </span>
            <span className={`text-base font-medium flex-1 ${!open && "hidden"}`}>
              Cerrar Sesión
            </span>
        </div>



      </div>
      <main className='min-h-screen w-full'>
        <Outlet/>
      </main>
    </div>
    
  )
}
export default NavbarTeacher;
import React from 'react'
import { LectionHome } from '../../../components/Student/Default/LectionHome';



function LectionHomePage() {
  return (
      <LectionHome/>
  )
}

export default LectionHomePage
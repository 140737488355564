import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

//Para Docentes
import NavbarTeacher  from '../components/Teacher/NavbarTeacher'
import TeacherHomePage from '../pages/TeacherHomePage'
import StudentProgressPage from '../pages/StudentProgressPage'
//Para temas de Docentes
import CreateTopicsPage from '../pages/Teacher/Create/CreateTopicsPage'
import UpdateTopicsPage from '../pages/Teacher/Update/UpdateTopicsPage'
import DeleteTopicsPage from '../pages/Teacher/Delete/DeleteTopicsPage'
import RestoreTopicsPage from '../pages/Teacher/Restore/RestoreTopicPage'
//Para lecciones de Docentes
import CreateLessonsPage from '../pages/Teacher/Create/CreateLessonsPage'
import UpdateLessonPage from '../pages/Teacher/Update/UpdateLessonPage'
import DeleteLessonPage from '../pages/Teacher/Delete/DeleteLessonPage'
import RestoreLessonPage from '../pages/Teacher/Restore/RestoreLessonPage'
//Para preguntas de Docentes
import CreateQuestionsPage from '../pages/Teacher/Create/CreateQuestionsPage'
import UpdateQuestionsPage from '../pages/Teacher/Update/UpdateQuestionsPage'
import DeleteQuestionsPage from '../pages/Teacher/Delete/DeleteQuestionsPage'
import RestoreQuestionsPage from '../pages/Teacher/Restore/RestoreQuestionsPage'

const useAuth = () => {
    const usuarioAT = localStorage.getItem('accessToken');
    const usuarioRT = localStorage.getItem('refreshToken');
    
    if(usuarioAT && usuarioRT){
        const decodedToken = jwtDecode(usuarioAT);
        const usuarioRol = decodedToken.Id_Rol;
        console.log(usuarioRol);
        if(!usuarioRol){
            return {
                auth: false,
                role: null,
            };
        }
        else{
            return {
                auth: true,
                role: usuarioRol
            };
        }
    }
    else{
        return {
            auth: false,
            role: null,
        };
    }
};

const ProtectedRoutesTeacher = () => {
    const {role} = useAuth();

    if(role === 1){
        return (
            <Routes>
                <Route path='/' element={<NavbarTeacher/>}>
                    {/*Esta ruta es para docentes */}
                    <Route index element={<TeacherHomePage />} />
                    
                    <Route path="AvanceLeccion" element={<StudentProgressPage/>}/>

                    <Route path="CrearTemas" element={<CreateTopicsPage/>}/>
                    <Route path="ModificarTemas" element={<UpdateTopicsPage/>}/>
                    <Route path="EliminarTemas" element={<DeleteTopicsPage/>}/>
                    <Route path="RestaurarTemas" element={<RestoreTopicsPage/>}/>

                    <Route path="CrearLecciones" element={<CreateLessonsPage/>}/>
                    <Route path="ModificarLecciones" element={<UpdateLessonPage/>}/>
                    <Route path="EliminarLecciones" element={<DeleteLessonPage/>}/>
                    <Route path="RestaurarLecciones" element={<RestoreLessonPage/>}/>

                    <Route path="CrearPreguntas" element={<CreateQuestionsPage/>}/>
                    <Route path="ModificarPreguntas" element={<UpdateQuestionsPage/>}/>
                    <Route path="EliminarPreguntas" element={<DeleteQuestionsPage/>}/>
                    <Route path="RestaurarPreguntas" element={<RestoreQuestionsPage/>}/>
                </Route>
            </Routes>
        );
    }
    else{
        return <Navigate to = "/" />
    }

};

export default ProtectedRoutesTeacher
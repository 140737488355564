import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import LoginPage from '../pages/Others/LoginPage';

const useAuth = () => {
  const usuarioAT = localStorage.getItem('accessToken');
  const usuarioRT = localStorage.getItem('refreshToken');

  if (usuarioAT && usuarioRT) {
    const decodedToken = jwtDecode(usuarioAT);
    const usuarioRol = decodedToken.Id_Rol;

    if (!usuarioRol) {
      return {
        auth: false,
        role: null,
      };
    }
    else {
      return {
        auth: true,
        role: usuarioRol
      };
    }
  }
  else {
    return {
      auth: false,
      role: null,
    };
  }
};

const PublicRoutes = () => {
  const { auth, role } = useAuth();

  //return auth?<Navigate to = "/Docentes"/>:<Outlet/>;

  console.log(!auth && !role);
  if (!auth && !role) {
    return (
      <Routes>
        <Route path='/' element={<LoginPage />}>
          <Route index element={<LoginPage />} />
        </Route>
      </Routes>
    );
  }
  else if (auth && role === 2) {
    return (
      <Navigate to="/Estudiantes" />
    );
  }
  else {
    return (
      <Navigate to="/Docentes" />
    );
  }

};

export default PublicRoutes